import React, { useCallback, useRef, useState } from "react";
import {
  FilmIcon,
  ChatAltIcon,
  ClockIcon,
  VideoCameraIcon,
  CheckIcon,
  BanIcon,
  RefreshIcon,
} from "@heroicons/react/outline";
import MicRecorder from "mic-recorder-to-mp3";
import Webcam from "react-webcam";
import { useHistory, useLocation } from "react-router-dom";
import qs from "query-string";
import { useEffect } from "react";
import { ClipLoader } from "react-spinners";
import {
  Card,
  CardBody,
  Modal,
  ModalBody,
  ModalFooter,
  Button, Container, Row, Col
} from "reactstrap";
import Timer from "components/timer";
import config from "config";
function RecordOnlyAudio({
  data,
  interviewId,
  deviceId,
  setQuestionCompleted,
  questionCompleted,
  callback,
  condd,
  setCondd,
  totalQues,
  index,
  question,
  setIndex,
  takesIND,
  setTakesIND,
  unlThinking,
  setUnlThinking,
  unlTakes,
  setUnlTakes,
  setTakesIndexer,
  takesIndexer,
}) {
  const location = useLocation();
  const query = qs.parse(location.search);
  const buttonNameRef = useRef();
  const webcamRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const [capturing, setCapturing] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState({});
  const [finishCapturing, setFinishCapturing] = useState(false);
  const [loading, setLoading] = useState(true);

  const [thinking, setThinking] = useState(true);

  const [timeOut, setTimeOut] = useState(false);

  const [outOfTakes, setoutOfTakes] = useState(false);
  const [opacity, setOpacity] = useState("0.3")
  const [modalOpen, setModalOpen] = useState(false);
  const [modalRetakeOpen, setModalRetakeOpen] = useState(false);
  // const Mp3Recorder = new MicRecorder({ bitRate: 128 });
  const [Mp3Recorder, setMp3Recorder] = useState(new MicRecorder({ bitRate: 128 }))


  const [microphoneIsAllowed, setMicrophoneIsAllowed] = useState(true);
  const [isRecording, setIsRecording] = useState(false);
  const [blobURL, setBlobURL] = useState({});
  const [audioFilePath, setAudioFilePath] = useState("");

  const checkMicrophonePermissions = () => {
    console.log('0000000000000 checkMicrophonePermissions: ');
    navigator.getUserMedia({ audio: true },
      () => {
        console.log('0000000000000 checkMicrophonePermissions: Permission Granted');
        setMicrophoneIsAllowed(true);
      },
      () => {
        console.log('0000000000000 checkMicrophonePermissions: Permission Denied');
        setMicrophoneIsAllowed(false);
      },
    );
  }

  const start = () => {
    console.log("11111111111111: start: ");
    setOpacity("1");
    setCapturing(true);


    if (!microphoneIsAllowed) {
      console.log('Permission Denied')
    } else {
      Mp3Recorder.start()
        .then(() => {
          setIsRecording(true);
        })
        .catch((e) => console.error(e))
    }
  };



  const stop = () => {
    setCapturing(false);
    setFinishCapturing(true);

    Mp3Recorder
      .stop()
      .getMp3()
      .then(([buffer, blob]) => {
        console.log("11111111111111: stop: ");
        console.log("11111111111111: stop: blob: ", blob);
        // const blobURL = URL.createObjectURL(blob);
        setBlobURL(URL.createObjectURL(blob));
        // setBlobURL(new Blob([blob["blob"]], blob["options"]));
        console.log("11111111111111: stop: blobURL: ", blobURL);
        console.log("11111111111111: stop: blob type: ", blob.type);


        setIsRecording(false);

        // this.setState({ blobURL, isRecording: fa
        setRecordedChunks(buffer);
        setFinishCapturing(true);
      }).catch((e) => console.log(e));
  };

  const retakeCallback = () => {
    setCondd(false);
    callback();
    setTakesIND(takesIND - 1);

    console.log(takesIND);
  };

  const lastSaveVideo = async () => {
    // console.log("222222222222222222222: lastSaveVideo: blobURL: ", blobURL);
    console.log("222222222222222222222: lastSaveVideo: recordedChunks: ", recordedChunks);

    const video = new File(recordedChunks, `${localStorage.getItem("candidatId")}--${question._id}`, {
      lastModified: new Date(),
      type: "audio/mp3"
    });
    console.log("222222222222222222222: lastSaveVideo: video_v0: ", video);
    const formData = new FormData();
    formData.append("video", video);
    // formData.append("filePath", `${localStorage.getItem("candLastName").toString()}--${localStorage.getItem("candName").toString()}--${question._id}`);

    // formData.append("video", video, {type: 'audio/wav'});
    formData.append("interview", interviewId);
    formData.append("question", query.questionId);
    formData.append("candidat", localStorage.getItem("candidatId"));
    console.log("222222222222222222222: lastSaveVideo: formData: ", formData);
    console.log("222222222222222222222: lastSaveVideo: formData.question: ", formData.question);
    console.log("222222222222222222222: lastSaveVideo: formData.video: ", formData.video);
    fetch(
      `${config.WS_BASE_URL}jobs/audiointerview/${interviewId}/${query.questionId
      }/${localStorage.getItem("candidatId")}`,
      {
        method: "POST",
        body: formData,
      }

    )
      .then((res) => console.log(res))
      .catch((err) => console.log(err));

    setCondd(false);
    callback();
  };



  console.log("id", query.questionId);
  useEffect(() => {
    checkMicrophonePermissions();
    setIndex(index);
    if (question.thinkTime == "Unlimited") {
      setUnlThinking(true);
    } else if (question.thinkTime != "Unlimited") {
      setUnlThinking(false);
    }
    console.log("indexxx2222222", takesIND);
    console.log("indexxxeeeeer", takesIndexer);
    console.log("out of takes", outOfTakes)

    if (takesIND <= 1 && takesIND >= 0) {
      console.log("indexxx", takesIND);
      console.log("outOfTakes", outOfTakes);
      setoutOfTakes(true);
    }

    setTimeout(() => {
      setLoading(false);
    }, 4000);
  }, []);

console.log('audio path',`${localStorage.getItem("candidatId")}--${question._id}`)







  //console.log("unlThininininini", unlThinking);
  return (
    <Container className="relative d-flex flex-column align-items-center">
      {/*
      <Row>
        <button onClick={start} disabled={isRecording}>
          Record
        </button>
        <Col>
        <button onClick={start} disabled={isRecording}>
          Record 2
        </button>
        </Col>
        <Col>
        <button onClick={stop} disabled={!isRecording}>
          Stop 2
        </button>
        </Col>
        <Col>
        <audio src={blobURL} controls="controls" />
        </Col>
      </Row>
      <Row>
    */}

      <Row style={{ width: "100%", backgroundColor: "#00000075" }}>
        <Col xs="4" style={{ display: "inline-flex" }}>
          <ClockIcon className="h-6 pr-2" color="white" />
          <h4 className="justify-left text-white" >
            Time limit: {question.timeLimit} minutes
          </h4>
        </Col>
        <Col xs="4"><h4 className="	text-white" style={{ textAlign: "center" }}> Question {index + 1} of {totalQues}:{" "}</h4></Col>


        <Col xs="4" style={{ display: "inline-flex" }}>
          <FilmIcon className="h-6 pr-2" color="white" />
          <h4 className=" text-white" >

            {unlTakes
              ? `Remaining Takes: Unlimited`
              : `Remaining Takes: ${takesIND - 1}`}
          </h4>
        </Col>

      </Row>
      <Row style={{ width: "100%" }}>
        <Col xs="2" >

        </Col>
        <Col xs="8"><h4 className="	flex items-center justify-center py-3  text-white rounded-t-md"
          style={{ textAlign: "center", background: "linear-gradient(87deg , #11cdef 0, #1171ef 100%)" }}> {question.question}</h4>
        </Col>


        <Col xs="2">

        </Col>

      </Row>
      {loading && (
        <div className="absolute flex justify-center items-center top-0 left-0 z-50 bg-gray-800 w-full h-full opacity-80">
          <ClipLoader color="#f00" />
        </div>
      )}
      <Row style={{ width: "100%" }}>
        
        
          <Row style={{ width: "100%" }}>
            <Col xs="4" >
              {!capturing && !finishCapturing && (
                <>
                  <Button
                    onClick={start}
                    className="btn position-absolute d-flex top-0 left-0 text-green px-3 py-2"
                    style={{ backgroundColor: "#00000075" }}
                  >
                    <VideoCameraIcon style={{ height: "1.5rem" }} />

                    {!thinking && !unlThinking && start()}

                    <p>Record</p>
                  </Button>
                </>
              )}
              {finishCapturing && (
                <>

                  <button
                    onClick={() => {
                      setModalOpen(!modalOpen);
                      setIndex(index + 1);
                    }}
                    className="btn position-absolute d-flex top-0 left-0 text-green px-3 py-2"
                    style={{ backgroundColor: "#00000075"}}
                  >
                    <CheckIcon style={{ height: "1.5rem" }} />
                    <p>Save</p>
                  </button>
                  {!outOfTakes && (
                    <button
                      onClick={() => {
                        setModalRetakeOpen(!modalRetakeOpen)
                      }}
                      className="btn position-absolute d-flex top-0 right-0 text-white px-3 py-2"
                      style={{ backgroundColor: "#00000075" }}
                    >
                      <RefreshIcon style={{ height: "1.5rem" }} />
                      <p>Retake</p>
                    </button>
                  )}
                </>
              )}
            </Col>
            <Col xs="4" >


              {thinking && !capturing && !finishCapturing && !unlThinking && (
                <Card
                  style={{
                    //width: "80%",
                    // top: "50%",
                    left: "25%",
                    //height: "50px",
                    marginBottom: "-3%",
                    backgroundColor: "#00000075",
                    alignItems: "center",
                  }}
                  className=" position-absolute d-flex  px-3 py-2"
                >
                  <CardBody
                    style={{
                      padding: "0.5rem",
                      color: "white",
                      display: "inline-flex",
                    }}
                  >
                    <Timer
                      time={question.thinkTime * 60 + 4}
                      value={thinking}
                      setValue={setThinking}
                    />
                    &nbsp; avant de commencer l'enregistrement
                  </CardBody>
                </Card>
              )}
              {capturing && !timeOut && (
                <Card
                  style={{
                    width: "80%",
                    //top: "50%",
                    left: "25%",
                    //height: "50px",
                    marginBottom: "-3%",
                    backgroundColor: "#00000075",
                    alignItems: "center",
                  }}
                  className=" position-absolute d-flex top-0 px-3 py-2"
                >
                  <CardBody
                    style={{
                      whiteSpace:'normal',
                      padding: "0.5rem",
                      color: "white",
                      textAlign: "center",
                      display: "inline-flex",
                    }}
                  >
                    <Row className='d-flex justify-content-center align-items-center'>
                     
                      <ClockIcon className="h-6 pr-2" />

                      <span style={{whiteSpace:'normal'}}>Time limit:</span>
                      <Timer
                      style={{whiteSpace:'normal'}}
                      time={question.timeLimit * 60}
                      value={timeOut}
                      setValue={setTimeOut}
                    />
                    </Row>
                  </CardBody>
                </Card>
              )}

            </Col>
            <Col xs="4" >
              {capturing && (
                <button
                  ref={buttonNameRef}
                  onClick={stop}
                  className="btn position-absolute d-flex top-0 right-0  text-red px-3 py-2"
                  style={{ backgroundColor: "#00000075" }}
                >
                  <BanIcon style={{ height: "1.5rem" }} />
                  {timeOut && !finishCapturing && stop()}
                  <p>Stop</p>
                </button>
              )}

            </Col>
          </Row>



      </Row>
      {/*
      <Row>
        <Col>
          <audio src={blobURL} controls="controls" />
        </Col>
      </Row>
      */}


      <Modal
        toggle={() => setModalRetakeOpen(!modalRetakeOpen)}
        isOpen={modalRetakeOpen}

      >
        <div className=" modal-header">
          <h4 className=" modal-title" id="exampleModalLabel">
            Êtes-vous sûr de vouloir reprendre la question ?
          </h4>
          <button
            aria-label="Close"
            className=" close"
            type="button"
            onClick={() => {
              setModalRetakeOpen(!modalRetakeOpen);
            }}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>

        <ModalFooter>
          <Button
            color="secondary"
            type="button"
            onClick={() => {
              setModalRetakeOpen(!modalRetakeOpen);
            }}
          >
            Annuler
          </Button>
          <Button
            color="primary"
            type="button"
            onClick={() => {
              retakeCallback();
            }}
          >
            Confirmer
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        toggle={() => setModalOpen(!modalOpen)}
        isOpen={modalOpen}
        onClosed={() => {
          setIndex(index - 1);
        }}
      >
        <div className=" modal-header">
          <h4 className=" modal-title" id="exampleModalLabel">
            Soumettre et passer à la question suivante
          </h4>
          <button
            aria-label="Close"
            className=" close"
            type="button"
            onClick={() => {
              setModalOpen(!modalOpen);
            }}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>

        <ModalFooter>
          <Button
            color="secondary"
            type="button"
            onClick={() => {
              setModalOpen(!modalOpen);
            }}
          >
            Annuler
          </Button>
          <Button
            color="primary"
            type="button"
            onClick={() => {
              lastSaveVideo();
              setTakesIndexer(-1);
            }}
          >
            Confirmer
          </Button>
        </ModalFooter>
      </Modal>

    </Container>
  );
}

export default RecordOnlyAudio;
