import React, { useState } from "react";
import { NavLink as NavLinkRRD, Link, useHistory } from "react-router-dom";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";
import routes from "routes.js";

//import EtrangerCDIenFranceOffres from "views/candidat/etrangerCDIenFranceOffres";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";

import {
  FiHome,
  FiChevronsRight,
  FiChevronsLeft,
  FiLogOut,
  FiArrowLeftCircle,
  FiArrowRightCircle,
} from "react-icons/fi";

import { logout } from "../../network/ApiAxios";
import { NavDropdown } from "react-bootstrap";


function InFranceCandidateSidebarV5() {
  var user;
  if (localStorage.getItem("user")) {
    user = JSON.parse(localStorage.getItem("user"));
  } else {
    user = { role: "none" };
  };
  const history = useHistory();
  const logOut = async () => {
    const token = localStorage.getItem("token");
    if (token) {
      const response = await logout(token);
      const { data } = response;
      if (data.success) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        // props.history.push("/auth/login");
        history.push("/home/homepage");
      }
    }
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Navbar
        className="navbar-vertical fixed-left navbar-light "
        expand="md"
        id="sidenav-main"
      >
        <Container fluid>
          {/* Toggler */}
          <button
            onClick={toggleNavbar}
            className="navbar-toggler"
            type="button"
          // onClick={this.toggleCollapse}
          >
            <span className="navbar-toggler-icon" />
          </button>
          {/* Brand */}

          <NavbarBrand
            className="pt-0"
            to="/infrancecandidate/home"
            tag={Link}
          >
            <img
              src={require("assets/img/brand/logo_transparent.png").default}
              alt={"..."}
              className=""
              style={{ maxHeight: "fit-content" }}
            />

          </NavbarBrand>



          <Collapse isOpen={isOpen} navbar>
            <Nav
              className="" navbar
            >
              {/* console.log("cas 333333333333333333333333") */}

              <NavItem>
                <NavLink
                  tag={NavLinkRRD}
                  // onClick={this.closeCollapse}
                  // activeClassName="active"
                  to="/infrancecandidate/home" >
                  <i className={"ni ni-briefcase-24"} />
                  Accueil
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  tag={NavLinkRRD}
                  // onClick={this.closeCollapse}
                  // activeClassName="active"
                  to="/candidate/parameters" >
                  <i className={"ni ni-briefcase-24"} />
                  Paramètres
                </NavLink>
              </NavItem>
              {/* <NavItem>
                <NavLink
                  tag={NavLinkRRD}
                  // onClick={this.closeCollapse}
                  // activeClassName="active"
                  to="/Infrapplypermanentfr/preferences" >
                  <i className={"ni ni-briefcase-24"} />
                  Préferences
                </NavLink>
              </NavItem> */}

              <NavItem>
                <NavLink
                  tag={NavLinkRRD}
                  to={`/MyProfile/${user._id}`} >
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mr-2">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
                  </svg>
                  Mon Profil
                </NavLink>
              </NavItem>

              <NavDropdown title="Offres d'emploi" id="basic-nav-dropdown">
                <NavItem>
                  <NavLink
                    tag={NavLinkRRD}
                    // onClick={this.closeCollapse}
                    // activeClassName="active"
                    to="/candidate/job-offers" >
                    <i className={"ni ni-briefcase-24"} />
                    Offres
                  </NavLink>
                </NavItem>

                {/* <NavItem>
                  <NavLink
                    tag={NavLinkRRD}
                    // onClick={this.closeCollapse}
                    // activeClassName="active"
                    to="/infrancecandidate/Jobs50k+" >
                    <i className={"ni ni-briefcase-24"} />
                    Offres de plus de 50K€
                  </NavLink>
                </NavItem> */}
              </NavDropdown>

              <NavDropdown title=" Missions en Freelance" id="basic-nav-dropdown">
                <NavItem>
                  <NavLink
                    tag={NavLinkRRD}
                    // onClick={this.closeCollapse}
                    // activeClassName="active"
                    to="/candidate/freelance/missions" >
                    <i className={"ni ni-briefcase-24"} />
                    Missions
                  </NavLink>
                </NavItem>

                {/* <NavItem>
                  <NavLink
                    tag={NavLinkRRD}
                    // onClick={this.closeCollapse}
                    // activeClassName="active"
                    to="/infrancecandidate/MissionsWithTJMG500+" >
                    <i className={"ni ni-briefcase-24"} />
                    Missions de plus de 500€
                  </NavLink>
                </NavItem> */}

                <NavItem>
                  <NavLink
                    tag={NavLinkRRD}
                    // onClick={this.closeCollapse}
                    // activeClassName="active"
                    to="/candidate/freelance/parameters" >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mr-2">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
                    </svg>
                    Paramètres
                  </NavLink>
                </NavItem>
              </NavDropdown>

              {/* <NavItem>
                <NavLink
                  tag={NavLinkRRD}
                  // onClick={this.closeCollapse}
                  // activeClassName="active"
                  to="/infrancecandidate/internshipOffers" >
                  <i className={"ni ni-briefcase-24"} />
                  Stages
                </NavLink>
              </NavItem> */}

              <NavItem>
                <NavLink
                  tag={NavLinkRRD}
                  // onClick={this.closeCollapse}
                  // activeClassName="active"
                  to="/candidate/trainings"
                >
                  <i className={"ni ni-briefcase-24"} />
                  Formations
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  tag={NavLinkRRD}
                  onClick={logOut}
                  // onClick={this.closeCollapse}
                  // activeClassName="active"
                  to="/home/login"
                >
                  <i className={"ni ni-briefcase-24"} />
                  Se déconnecter
                </NavLink>
              </NavItem>

            </Nav>
          </Collapse>


          {/* Divider */}
          <hr className="my-3" />




        </Container>
      </Navbar>




    </>


  )

};


export default InFranceCandidateSidebarV5;
