import React from "react";
import { NavLink as NavLinkRRD, Link, useHistory } from "react-router-dom";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";
import routes from "routes.js";

import { logout } from "../../network/ApiAxios";

//import EtrangerCDIenFranceOffres from "views/candidat/etrangerCDIenFranceOffres";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";

import {
  FiHome,
  FiChevronsRight,
  FiChevronsLeft,
  FiLogOut,
  FiArrowLeftCircle,
  FiArrowRightCircle,
} from "react-icons/fi";




function AnonymousSidebar () {


    return (
      <>
        <Navbar 
        className="navbar-vertical fixed-left navbar-light "
        expand="md"
        id="sidenav-main"
      >
         <Container fluid>
          {/* Toggler */}
          <button
            className="navbar-toggler"
            type="button"
            // onClick={this.toggleCollapse}
          >
            <span className="navbar-toggler-icon" />
          </button>
          {/* Brand */}

            <NavbarBrand 
            className="pt-0"
            to="/home/homepage"
            tag={Link}
            >
            <img
              src={require("assets/img/brand/logo_transparent.png").default}
              alt={"..."}
              className=""
              style={{ maxHeight: "fit-content" }}
          />
              
           </NavbarBrand>




          </Container>
    </Navbar>




      </> 

    
)

    };


export default AnonymousSidebar;
