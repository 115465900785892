import React, { useEffect, useRef, useState } from "react";
import Record from "components/Record";
import { useHistory, useLocation } from "react-router-dom";
import qs from "query-string";
import axios from "axios";
import { ViewListIcon, BriefcaseIcon } from "@heroicons/react/outline";
import CameraDetect from "components/CameraDetect";
//import InterviewInfo from "../intInfo";
import InterviewInfoInFranceCandidatev2 from "components/interview/InterviewInfoInFranceCandidatev2";
import { ClipLoader } from "react-spinners";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Image,
  Row,
  Col,
} from "reactstrap";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import ChangingProgressProvider from "../changingProgressProvider";
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import config from "config";

const instance = axios.create({
  baseURL: config.WS_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? token : "";
  config.headers.ContentType = "application/json";
  return config;
});


function ShowInterviewInFranceCandidate({ questions, data, interviewId, interview, interviewMethod,props }) {
  var user;
  if (localStorage.getItem("user")) {
    user = JSON.parse(localStorage.getItem("user"));
  } else {
    user = { role: "none" };
  }

  const location = useLocation();
  const history = useHistory();
  const query = qs.parse(location.search);

  const [deviceId, setDeviceId] = useState(null);
  const [INDEX, setIndex] = useState(0);
  const [QUESTION, setQuestion] = useState("");
  const [questionCompleted, setQuestionCompleted] = useState([]);

  const [condition, setCondition] = useState(false);
  const [display, setDisplay] = useState("");
  const current = questions[INDEX];
  const [modalOpen, setModalOpen] = useState(false);
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);

  const setCompleted = () => {
    instance.put( `complete/${localStorage.getItem("participationId")}/${interview.job._id}`);
  };

  const callback = () => {
    if (INDEX >= questions.length) {
      setCondition(true);
      setCompleted();
      setTimeout(() => {
        
        history.push( "/candidate/home");
      
        }, 4000);
    } else {
      history.push(`/interview/${interviewId}?questionId=${current._id}`);
      setQuestion(current);
      setDisplay("none");
      // console.log("current", current);
    }
  };
  return (
    <div className=" ">
      {condition && (
        <>
          {loading && (
            <div className="absolute flex justify-center items-center top-0 left-0 z-50 bg-gray-800 w-full h-full opacity-80">
              <div style={{ display: "block" }}>
                <h3 className=" text-white text-center">
                  veuillez patienter, sauvegarde des données en cours ...
                </h3>
                <div style={{ transform: "translate(180%)", width: "20%" }}>
                  <ChangingProgressProvider
                    values={[
                      3, 6, 9, 12, 15, 18, 21, 25, 28, 31, 34, 38, 42, 45, 48,
                      51, 55, 59, 62, 64, 67, 70, 73, 76, 79, 82, 85, 90, 95,
                      99,
                    ]}
                  >
                    {(percentage) => (
                      <CircularProgressbar
                        value={percentage}
                        text={`${percentage}%`}
                        styles={buildStyles({
                          pathTransitionDuration: 1,
                        })}
                      />
                    )}
                  </ChangingProgressProvider>
                </div>
                <h4 className="text-white text-center">
                  Ne fermez pas la page, cela peut prendre un certain temps!
                </h4>
              </div>
            </div>
          )}
          <Row
            style={{
              width: "100%",
              backgroundColor: "#e3eafd",
              margin: "0%",
            }}
            className="align-items-center justify-content-xl-between "
          >
            <Col xs="6">
              <img
                alt="..."
                className="shadow-lg"
                src={require("assets/img/brand/interview.jpg").default}
              />
            </Col>

            <Col xs="6">
              <div className=" ">
                <div
                  className="flex flex-col space-y-3 bg-white shadow-lg px-3 py-3 w-1/2 ml-2 h-1/3"
                  style={{ transform: "translate(50%)" }}
                >
                  <div className="flex space-x-2 items-center pb-2 border-b border-gray-500">
                    <h3 className=" text-center">
                      Merci pour votre participation !
                    </h3>
                    
                  </div>
                  <p className="text-sm text-center "> Redirecting .... </p>
                </div>
              </div>
            </Col>
          </Row>
        </>
      )}
      {/* questions */}

      {/* video interview  */}
      {!condition && (
        <Row
          style={{
            width: "100%",
            backgroundColor: "#e3eafd",
            display: display,
            margin: "0%",
          }}
          className="align-items-center justify-content-xl-between "
        >
          {/*
          <Col xs="6">
            <img
              alt="..."
              className="shadow-xl"
              src={require("assets/img/brand/20943565.jpg").default}
            />
          </Col>
        */}
          <Col xs="3">
          </Col>

          <Col xs="6">
            <div className=" ">
              <div className="flex flex-col space-y-3 bg-white shadow-xl px-3 py-3 ml-2 h-1/3">
                <div className="flex space-x-2 items-center pb-2 border-b border-gray-500">
                  <BriefcaseIcon className="h-8" />
                  <h3>Détails de votre entretien</h3>
                </div>
                <span
                  className="pt-2 font-bold text-black"
                  style={{ display: "inline-flex" }}
                >
                  Titre du poste:&nbsp;<h4> {interview.job.title}</h4>
                </span>
                <span
                  className="pt-2 font-bold text-black "
                  style={{ display: "inline-flex" }}
                >
                  Emplacement:&nbsp; <h4>{interview.job.location} </h4>
                </span>
                <span
                  className="pt-2 font-bold text-black pb-2 border-b border-gray-500 "
                  style={{ display: "inline-flex" }}
                >
                  Description:&nbsp; <h4>{interview.job.description}</h4>
                </span>
                <div className="flex space-x-2">
                  <Button
                    style={{
                      transform: "translate(50%)",
                      backgroundColor: "#11a0ef",
                    }}
                    className=" text-center text-white"
                    onClick={() => {
                      setModalOpen(!modalOpen);
                    }}
                  >
                    Commencer
                  </Button>
                </div>
              </div>
            </div>
          </Col>
          <Col xs="3">
          </Col>
        </Row>
      )}

      <Modal toggle={() => setModalOpen(!modalOpen)} isOpen={modalOpen}>
        <div className=" modal-header">
          {!condition && !deviceId && (
            <CameraDetect
              setDeviceId={setDeviceId}
              callback={callback}
              setModalOpen={setModalOpen}
              modalOpen={modalOpen}
            />
          )}
          <button
            aria-label="Close"
            className=" close"
            type="button"
            onClick={() => {
              setModalOpen(!modalOpen);
            }}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
      </Modal>
      {/* {!condition&&query.questionId && !deviceId && (
        <CameraDetect setDeviceId={setDeviceId} />
      )} */}
      {!condition && query.questionId && deviceId && (
        <InterviewInfoInFranceCandidatev2
          index={INDEX}
          totalQues={questions.length}
          question={QUESTION}
          setIndex={setIndex}
          callback={callback}
          data={data}
          interviewId={interviewId}
          deviceId={deviceId}
          questionCompleted={questionCompleted}
          setQuestionCompleted={setQuestionCompleted}
          interviewMethod={interviewMethod}
          FollowUp_IACode_Questions={interview?.job?.Add_FollowUp_IACode_Questions}
        />
      )}
    </div>
  );
}

export default ShowInterviewInFranceCandidate;
