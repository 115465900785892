import React from "react";
import { NavLink as NavLinkRRD, Link, useHistory } from "react-router-dom";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";
import routes from "routes.js";

//import EtrangerCDIenFranceOffres from "views/candidat/etrangerCDIenFranceOffres";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";

import {
  FiHome,
  FiChevronsRight,
  FiChevronsLeft,
  FiLogOut,
  FiArrowLeftCircle,
  FiArrowRightCircle,
} from "react-icons/fi";

import { logout } from "../../network/ApiAxios";


function SidebarCandidateV3 () {
  var user;
  if (localStorage.getItem("user")) {
  user = JSON.parse(localStorage.getItem("user"));
   } else {
   user = { role: "none" };
   };
   const history = useHistory();
   const logOut = async () => {
    const token = localStorage.getItem("token");
    if (token) {
      const response = await logout(token);
      const { data } = response;
      if (data.success) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        // props.history.push("/auth/login");
        history.push("/home/homepage");
      }
    }
  };

    return (
      <>
        <Navbar 
        className="navbar-vertical fixed-left navbar-light "
        expand="md"
        id="sidenav-main"
      >
         <Container fluid>
          {/* Toggler */}
          <button
            className="navbar-toggler"
            type="button"
            // onClick={this.toggleCollapse}
          >
            <span className="navbar-toggler-icon" />
          </button>
          {/* Brand */}

            <NavbarBrand 
            className="pt-0"
            to="/candidate/home"
            tag={Link}
            >
            <img
              src={require("assets/img/brand/logo_transparent.png").default}
              alt={"..."}
              className=""
              style={{ maxHeight: "fit-content" }}
          />
              
           </NavbarBrand>




          <Nav 
                className="" navbar
              >
              {/* console.log("cas 333333333333333333333333") */}
            
              <NavItem> 
                <NavLink
                  tag={NavLinkRRD}
                  // onClick={this.closeCollapse}
                  // activeClassName="active"
                  to="/candidate/home" >
                  <i className={"ni ni-briefcase-24"} />
                  Accueil
                </NavLink>
              </NavItem>
            {/* 
              <NavItem> 
                <NavLink
                  tag={NavLinkRRD}
                  // onClick={this.closeCollapse}
                  // activeClassName="active"
                  to="/candidate/parameters" >
                  <i className={"ni ni-briefcase-24"} />
                  Paramètres
                </NavLink>
              </NavItem>
            */}
              <NavItem> 
                <NavLink
                tag={NavLinkRRD}
                // onClick={this.closeCollapse}
                // activeClassName="active"
                to="/candidate/myprofile" >
                <i className={"ni ni-briefcase-24"} />
                Mon profil
                </NavLink>
                </NavItem>
                             
                <NavItem> 
                  <NavLink
                    tag={NavLinkRRD}
                    // onClick={this.closeCollapse}
                    // activeClassName="active"
                    to="/candidate/jobs" >
                    <i className={"ni ni-briefcase-24"} />
                    Offres
                  </NavLink>
                </NavItem>

                <NavItem> 
                  <NavLink
                    tag={NavLinkRRD}
                    onClick={logOut}
                    // onClick={this.closeCollapse}
                    // activeClassName="active"
                    to="/home/login" 
                    >
                    <i className={"ni ni-briefcase-24"} />
                    Se déconnecter
                  </NavLink>
                </NavItem>
              
              </Nav>

            
            {/* Divider */}
            <hr className="my-3" />




          </Container>
    </Navbar>




      </> 

    
)

    };


export default SidebarCandidateV3;
