import { getActiveProfileTypes } from 'network/ApiAxios';
import { options_Pays } from 'options'
import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, CardFooter, Col, Input, Row } from 'reactstrap'
import Select from 'react-select';


const SearchTalentV1 = ({ Search, Location, onClick }) => {
    const [activeProfileTypes, setActiveProfileTypes] = useState([]);
    const [pays, setPays] = useState([]);
    const [search, setSearch] = useState(Search || null);
    const [location, setLocation] = useState(Location || null);
    const [searchError, setSearchError] = useState(null);
    const [locationError, setLocationError] = useState(null);

    const fetchActiveProfileTypes = async () => {
        try {
            const { data } = await getActiveProfileTypes()
            data && setActiveProfileTypes(data.map(item => ({ value: item.jobID, label: item.name })))
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetchActiveProfileTypes()
    }, [])

    useEffect(() => {
        const handleKeyPress = (e) => {
            if (e.key === 'Enter') {
                HandleClick();
            }
        }

        document.addEventListener('keypress', handleKeyPress);
        return () => {
            document.removeEventListener('keypress', handleKeyPress);
        }
    }, [location, search])

    const HandleSelectChange = (option, setFunc) => {
        setFunc(option.value)
    }

    const customStyles = {
        menuPortal: base => ({ ...base, zIndex: 9999 }),
        menu: provided => ({ ...provided, position: 'absolute' }),
        control: (baseStyles, state) => ({
            ...baseStyles,
            height: "46px",
        })
    };

    const SelectValidation = () => {
        let isValid = true;
        if (!search) {
            isValid = false;
            //setIdx(1)
            setSearchError("Indiquez un métier ou une compétence (ex: web designer, java maven spring, développeur iOS, etc.)")
        }
        if (!location) {
            isValid = false;
            //setIdx(2)
            setLocationError("Indiquez le lieu")
        }
        return isValid
    }

    const HandleClick = () => {
        //setError(null)
        setSearchError(null);
        setLocationError(null);
        if (SelectValidation()) {
            onClick(search, location)
        }
    }

    const inputStyle = {
        boxSizing: "border-box",
        borderRadius: "4px",
        backgroundColor: "white",
        backgroundImage: `url("data:image/svg+xml,%3csvg%20width='18'%20height='18'%20viewBox='0%200%2018%2018'%20fill='none'%20xmlns='http://www.w3.org/2000/svg'%3e%3cg%20clip-path='url(%23clip0_143_287)'%3e%3cpath%20d='M12.5578%2012.5583L16.9995%2017M13.7796%206.26994C15.0166%209.18048%2013.6599%2012.5427%2010.7493%2013.7796C7.83879%2015.0166%204.47659%2013.6599%203.23964%2010.7493C2.00269%207.83879%203.3594%204.47659%206.26994%203.23964C9.18048%202.00269%2012.5427%203.3594%2013.7796%206.26994Z'%20stroke='%23737370'%20stroke-width='1.5'%20stroke-linecap='round'%20stroke-linejoin='round'/%3e%3c/g%3e%3cdefs%3e%3cclipPath%20id='clip0_143_287'%3e%3crect%20width='17.9995'%20height='18'%20fill='white'/%3e%3c/clipPath%3e%3c/defs%3e%3c/svg%3e")`,
        backgroundPosition: "10px 10px",
        backgroundRepeat: "no-repeat",
        paddingLeft: "40px"
    }

    useEffect(()=>{
        SelectValidation()
    },[])

    return (
        <Card className="bg-transparent shadow p-2">
            <CardBody>
                <Row /*className='justify-content-between'*/>
                    <Col sm='1'></Col>
                    <Col sm='4'>
                        <Input
                            style={inputStyle}
                            //style={{ marginLeft: "20px"}}
                            //className='pr-5'
                            type='text'
                            value={search}
                            placeholder='Essayez : "Javascript", "développeur web"'
                            onChange={e => {
                                if(!e.target.value){
                                    SelectValidation()
                                }
                                else {
                                    setSearchError(null);
                                    setSearch(e.target.value)
                                }
                                
                            }}
                        />
                        {searchError && (
                            <div className="text-muted font-italic">
                                <small>
                                    <span className="text-red font-weight-700">{searchError}</span>
                                </small>
                            </div>
                        )}
                    </Col>
                    <Col sm='4'>
                        <div className='d-flex'>
                            <Select
                                placeholder="Exemple : France"
                                className="w-full"
                                options={options_Pays}
                                onChange={(opt) => {
                                    if(!opt){
                                        SelectValidation()
                                    }
                                    setLocationError(null);
                                    HandleSelectChange(opt, setLocation)
                                }}
                                value={options_Pays.find(opt => opt.value === location)}
                                styles={{ ...customStyles, menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                menuPortalTarget={document.body}
                            //onKeyPress={HandleClick}
                            />

                        </div>
                        {locationError && (
                            <div className="font-italic">
                                <small>
                                    <span className="text-red font-weight-700">{locationError}</span>
                                </small>
                            </div>
                        )}
                    </Col>
                    <Col sm='3' /*className='d-flex justify-content-end align-items-center'*/>
                        <Button 
                            size="lg"
                            className='mr-5 '
                            onClick={HandleClick}
                            color='primary'
                        //onKeyPress={HandleClick}
                        > Lancer la recherche</Button>
                    </Col>
                    {/* <Col sm='2'>
                        
                    </Col> */}

                </Row>
            </CardBody>
            {/* <CardFooter className='d-flex justify-content-center'>
                {error && (
                    <div className="text-muted font-italic">
                        <small>
                            <span className="text-red font-weight-700">{error}</span>
                        </small>
                    </div>
                )}
            </CardFooter> */}
        </Card>
    )
}

export default SearchTalentV1