import React, { useCallback, useRef, useState } from "react";
import {
    FilmIcon,
    ChatAltIcon,
    ClockIcon,
    VideoCameraIcon,
    CheckIcon,
    BanIcon,
    RefreshIcon,
} from "@heroicons/react/outline";
import {
    CircularProgressbar,
    CircularProgressbarWithChildren,
    buildStyles,
} from "react-circular-progressbar";
import ChangingProgressProvider from "components/changingProgressProvider";
import Webcam from "react-webcam";
import { useHistory, useLocation } from "react-router-dom";
import qs from "query-string";
import { useEffect } from "react";
import { ClipLoader } from "react-spinners";
import {
    Card,
    CardBody,
    Modal,
    ModalBody,
    ModalFooter,
    Button,
    Container,
    Row,
    Col,
    GroupAddon,
    FormGroup,
    Input,
    Label,
    CardImg,
    Spinner
} from "reactstrap";
import Timer from "components/timer";
import Editor from '@monaco-editor/react'


import config from "config";
import { fetchGeneralParamByLabel } from "network/ApiAxios";
import FollowUpIaQuestions from "./FollowUpIaQuestions";
function RecordCodingQuestion({
    data,
    interviewId,
    deviceId,
    setQuestionCompleted,
    questionCompleted,
    callback,
    condd,
    setCondd,
    totalQues,
    index,
    question,
    setIndex,
    takesIND,
    setTakesIND,
    unlThinking,
    setUnlThinking,
    unlTakes,
    setUnlTakes,
    setTakesIndexer,
    takesIndexer,
    FollowUp_IACode_Questions
}) {
    const location = useLocation();
    var beginRecord = false;
    const query = qs.parse(location.search);
    const mediaRecorderRef = useRef(null);
    const [beginRecording, setBeginRecording] = useState(false);
    const [capturing, setCapturing] = useState(false);
    const [recordedChunks, setRecordedChunks] = useState({});

    const [stopRecordingFunctionCalled, setStopRecordingFunctionCalled] = useState(false);
    const [lastSaveFunctionCalled, setLastSaveFunctionCalled] = useState(false);
    const [finishCapturing, setFinishCapturing] = useState(false);
    const [loading, setLoading] = useState(true);


    const [thinking, setThinking] = useState(true);

    const [timeOut, setTimeOut] = useState(false);
    const webcamRef = useRef(null);

    const [outOfTakes, setoutOfTakes] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalRetakeOpen, setModalRetakeOpen] = useState(false);
    const [opacity, setOpacity] = useState("0.3")


    const [isLoadingTest, setIsLoading] = useState(false)
    const [resultatTest, setResultatTest] = useState(null)
    const [candidateCode, setCandidateCode] = useState("")
    const [generalInstruction, setGeneralInstruction] = useState(null);
    //const [followUpQuestion, setFollowUpQuestion] = useState(null);
    //const [followUpResponse, setFollowUpResponse] = useState(null);

    /* const HandleResponseChange = (e) => {
        setFollowUpResponse(e.target.value)
    } */

    const handleStartCapture = useCallback(() => {
        // console.log("TTTTTTTTTTTTTTTT: RecordForQCMQuestionV2: handleStartCapture: ");
        beginRecord = true;

        setOpacity("1");
        setCapturing(true);
        setBeginRecording(true);

        mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
            mimeType: "video/webm",
        });
        mediaRecorderRef.current.addEventListener(
            "dataavailable",
            handleDataAvailable
        );
        mediaRecorderRef.current.start();
    }, [webcamRef, setCapturing, mediaRecorderRef]);

    const handleDataAvailable = useCallback(
        ({ data }) => {
            if (data.size > 0) {
                setRecordedChunks(data);
            }
        },
        [setRecordedChunks]
    );

    const handleStopCapture = useCallback(() => {
        // console.log("TTTTTTTTTTTTTTTT: RecordForQCMQuestionV2: handleStopCapture: ");
        // console.log("TTTTTTTTTTTTTTTT: RecordForQCMQuestionV2: handleStopCapture: beginRecording: ", beginRecording);
        if (!stopRecordingFunctionCalled) {
            setStopRecordingFunctionCalled(true);
            // console.log("TTTTTTTTTTTTTTTT: RecordForQCMQuestionV2: handleStopCapture: before mediaRecorderRef.current.stop: ");
            mediaRecorderRef.current.stop();
        }



        setCapturing(false);
        setFinishCapturing(true);
        setStopRecordingFunctionCalled(true);
        // setIndex(index + 1);
    }, [mediaRecorderRef, webcamRef, setCapturing]);

    const lastSaveVideo = async () => {
        setLastSaveFunctionCalled(true);
        // console.log("TTTTTTTTTTTTTTTT: RecordForQCMQuestionV2: lastSaveVideo: ");
        // setModalOpen(!modalOpen);
        // setNextQuestionModalOpen(!nextQuestionModalOpen);

        const blob = new Blob([recordedChunks], {
            type: "video/webm",
        });
        const video = new File([blob], `${localStorage.getItem("candidatId")}--${question._id}`, {
            lastModified: new Date(),
            type: blob.type,
        });
        const formData = new FormData();

        formData.append("video", video);
        //formData.append("interview", interviewId);
        //formData.append("question", query.questionId);
        //formData.append("candidat", localStorage.getItem("candidatId"));
        formData.append("codeResponse", candidateCode);
        /* if (FollowUp_IACode_Questions) {
            followUpQuestion && formData.append("followUp_GPT_question", followUpQuestion);
            followUpResponse && formData.append("followUp_GPT_CodeResponse", followUpResponse);
        } */

        fetch(
            `${config.WS_BASE_URL}jobs/startCodingQuestionInterview/${interviewId}/${query.questionId
            }/${localStorage.getItem("candidatId")}`,
            {
                method: "POST",
                body: formData,
            }
        )
            .then((res) => {
                console.log(" lastSaveVideo: fetch res", res);

            }
            )
            .catch((err) => {
                console.log("lastSaveVideo: fetch : err: ", err);

            }
            );

        setLastSaveFunctionCalled(false);
        setCondd(false);
        callback();






    };
    const retakeCallback = () => {
        setCondd(false);
        callback();
        setTakesIND(takesIND - 1);

        // console.log(takesIND);
    };

    const GetGeneralInstruction = async () => {
        try {
            const { data } = await fetchGeneralParamByLabel("Instructions générales pour les questions de type Coding")
            setGeneralInstruction(data[0].Parameter)
            //console.log('id',data[0].Parameter)
        } catch (error) {
            console.log(error)
        }
    }


    useEffect(() => {

        setIndex(index);
        if (question.thinkTime === "Unlimited") {
            setUnlThinking(true);
        } else if (question.thinkTime !== "Unlimited") {
            setUnlThinking(false);
        }
        // console.log("indexxx2222222", takesIND);
        // console.log("indexxxeeeeer", takesIndexer);
        // console.log("out of takes",outOfTakes)

        if (takesIND <= 1 && takesIND >= 0) {
            // console.log("indexxx", takesIND);
            // console.log("outOfTakes", outOfTakes);
            setoutOfTakes(true);
        }

        setTimeout(() => {
            setLoading(false);
            handleStartCapture()
        }, 12000);
        GetGeneralInstruction()
    }, []);

    //console.log('FollowUp_IACode_Questions',FollowUp_IACode_Questions)

    const HandleSubmitClick = () => {
        handleStopCapture();
        setModalOpen(!modalOpen);
        setStopRecordingFunctionCalled(true);
        setIndex(index + 1);
    }


    //console.log("unlThininininini", unlThinking);
    return (
        <>

            <div className="relative d-flex flex-column align-items-center">
                <>
                    {!timeOut &&
                        <>
                            <Row
                                style={{ width: "100%" }}
                            >
                                <Col xs="1" >
                                </Col>


                                <Col xs="5">
                                    <Row className="square border form-control-label flex items-center justify-center" style={{ width: "100%" }}>
                                        <h4 className="	text-black"> Question {index + 1} / {totalQues} - Temps restant: {" "}</h4>
                                        {!timeOut && (
                                            <h4 className="text-black">
                                                {/* <ClockIcon className="h-6 pr-2" /> */}
                                                {/*                                                 {console.log('time s', ((parseInt(question.timeLimit.slice(0,2)) *60) + parseInt(question.timeLimit.slice(3))) * 60)}
 */}                                                <Timer
                                                    time={/*question.timeLimit * 60*/ ((parseInt(question.timeLimit.slice(0, 2)) * 60) + parseInt(question.timeLimit.slice(3))) * 60}
                                                    value={timeOut}
                                                    setValue={setTimeOut}
                                                />
                                            </h4>
                                        )}

                                    </Row>
                                    <Row style={{ width: "100%" }} className='mt-1 mb-1'>
                                        <i style={{ zoom: '180%' }} className="ni ni-book-bookmark"></i>

                                        <Label className='font-bold text-black ml-2'>Enoncé</Label>
                                    </Row>
                                    <Row style={{ width: "100%" }}>
                                        <Input
                                            // type="text"
                                            type="textarea"
                                            rows="5"
                                            className="flex items-center justify-center text-black"
                                            // className="border-[.8px] px-2 border-gray-300 flex-grow py-[1.7px] text-sm h-6"
                                            value={question.question}
                                            readOnly
                                        />

                                    </Row>
                                    {question.questionStatementImage &&
                                        <Row style={{ width: "100%" }}>
                                            <CardImg
                                                src={`${config.Data_URL}${question.questionStatementImage}`}
                                                // className="rounded-circle"
                                                onError={(e) => {
                                                    e.target.src =
                                                        require("assets/img/brand/500.png").default;
                                                }}
                                                style={{
                                                    width: "100%",
                                                    height: "100%",
                                                    // height: "150px",
                                                    // alignSelf: "center",
                                                    // objectFit: "cover",
                                                }}
                                            //alt="Responsive image"
                                            />
                                        </Row>
                                    }
                                    {question?.Instructions && <>
                                        <Row style={{ width: "100%" }}>
                                            <h4
                                                className="flex items-center justify-center text-black"
                                                style={{ textAlign: "center" }}
                                            >
                                                Autres Instructions
                                            </h4>
                                        </Row>
                                        <Row style={{ width: "100%" }}>
                                            <Input
                                                // type="text"
                                                type="textarea"
                                                rows="5"
                                                className="flex items-center justify-center text-black"
                                                // className="border-[.8px] px-2 border-gray-300 flex-grow py-[1.7px] text-sm h-6"
                                                value={question.Instructions}
                                                readOnly
                                            />

                                        </Row>
                                    </>}
                                    {
                                        generalInstruction && <>
                                            <Row style={{ width: "100%" }}>
                                                <h4
                                                    className="flex items-center justify-center text-black"
                                                    style={{ textAlign: "center" }}
                                                >
                                                    NB :
                                                </h4>
                                            </Row>
                                            <Row style={{ width: "100%" }}>
                                                <div dangerouslySetInnerHTML={{ __html: generalInstruction }} />
                                            </Row>
                                        </>
                                    }
                                    {/* begin Implementation fct */}
                                    {/* <div className='d-flex mt-4'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20"><path fill="#525f7f" fill-rule="evenodd" d="M19.605 5.306a7.754 7.754 0 0 1-1.876 7.932 7.752 7.752 0 0 1-7.565 1.987L5.609 19.78a.756.756 0 0 1-1.067 0L.22 15.458a.756.756 0 0 1 0-1.067l4.555-4.555a7.752 7.752 0 0 1 1.987-7.565A7.754 7.754 0 0 1 14.694.395L9.688 5.4l1.038 3.873 3.873 1.038 5.006-5.006Z" clip-rule="evenodd"></path></svg>
                                        <Label className='font-bold text-black ml-2'>Implementation</Label>
                                    </div>

                                    <div className='mb-3 bg-secondary p-2'>
                                        <div className='d-flex'>
                                            <svg className='mt-1' xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="none" viewBox="0 0 14 14"><path fill="currentColor" fill-rule="evenodd" d="M7 0a7 7 0 1 0 0 14A7 7 0 0 0 7 0ZM4.89 11.5c1.08 0 1.72-.5 1.92-1.5l.92-4.63h1.03l.22-1.12H7.96l.2-1.03h.96l.24-1.12H8.6c-1.08 0-1.72.5-1.92 1.5l-.13.65h-.69l-.22 1.12h.69l-1 5.01h-.96l-.24 1.12h.76Z" clip-rule="evenodd"></path></svg>
                                            <Label className='font-bold text-black ml-2'>Fonction</Label>
                                        </div>

                                        <p className='px-sm-4'>Implémenter la fonction  : <span className='font-bold '>{question?.CodingQ_FunctionName}</span></p>

                                    </div>


                                    <div className='mb-3 bg-secondary p-2'>
                                        <div className='d-flex '>
                                            <svg style={{ height: '1.2rem' }} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 21 20"><path fill="currentColor" d="M10.5 0C4.929 0 .5 4.429.5 10s4.429 10 10 10 10-4.429 10-10-4.429-10-10-10Zm3.286 10.714-3.572 3.572c-.285.285-.428.285-.714.285s-.571-.142-.714-.285c-.572-.286-.572-1-.143-1.429L11.5 10 8.643 7.143a1.121 1.121 0 0 1 0-1.572 1.121 1.121 0 0 1 1.571 0l3.572 3.572c.428.571.428 1.143 0 1.571Z"></path></svg>
                                            <Label className='font-bold text-black ml-2'>Paramètres</Label>
                                        </div>
                                        {question?.CodingQ_FctParameters?.map((param, i) => <Row key={i}>
                                            <p className='px-sm-5'><span className='font-bold'>{param.name}</span> : {param.typed}</p>
                                        </Row>)}
                                    </div>

                                    <div className='mb-3 bg-secondary p-2'>
                                        <div className='d-flex '>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                                                <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-4.28 9.22a.75.75 0 000 1.06l3 3a.75.75 0 101.06-1.06l-1.72-1.72h5.69a.75.75 0 000-1.5h-5.69l1.72-1.72a.75.75 0 00-1.06-1.06l-3 3z" clipRule="evenodd" />
                                            </svg>
                                            <Label className='font-bold text-black ml-2'>Valeur de retour</Label>
                                        </div>
                                        <p className='px-sm-4'><span className='font-bold'>{question?.CodingQ_FctReturnValue?.name}</span> :  {question?.CodingQ_FctReturnValue?.type}</p>
                                    </div> */}
                                    {/* End Implementation fct */}

                                </Col>



                                <Col xs="5" style={{ pl: 5, pr: 5 }}>

                                    <Row className="square border form-control-label flex items-center justify-center">

                                        <h4
                                            className="flex items-center justify-center text-black"
                                        >
                                            {question.Domain}
                                        </h4>

                                    </Row>

                                    <Row>

                                    </Row>
                                    {/* réponse candidat begin*/}
                                    <div className='d-flex mb-3 mt-1'>
                                        <svg className='mt-1' xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="none" data-metadata="ide,home" viewBox="0 0 14 14" class="icon-0-2-2340"><path fill="currentColor" d="M.016 13.477A.412.412 0 0 0 .41 14a.38.38 0 0 0 .113-.016l3.83-1.094-3.244-3.243-1.093 3.83ZM13.64 2.101 11.9.361a1.234 1.234 0 0 0-1.745-.003l-.87.872 3.481 3.482.872-.87c.232-.234.358-.543.358-.872a1.213 1.213 0 0 0-.356-.869ZM1.571 8.946 8.706 1.81l3.483 3.483-7.135 7.135-3.483-3.483Z"></path></svg>
                                        <Label className='font-bold text-black ml-2'>Réponse</Label>
                                    </div>
                                    {/* <Editor
                                        height="40vh"
                                        defaultLanguage="javascript"
                                        value={candidateCode}
                                        options={{
                                            fontSize: 12,
                                            folding: true
                                        }}
                                        onChange={(code) => setCandidateCode(code)}

                                    /> */}
                                    <Input
                                        type="textarea"
                                        rows="10"
                                        onChange={e => setCandidateCode(e.target.value)}
                                        placeholder="Mettre votre réponse ici"
                                    />

                                    {/* <div className='d-flex mt-3 mb-3'>
                                        <svg className='mt-1' xmlns="http://www.w3.org/2000/svg" width="18" height="16" fill="none" viewBox="0 0 18 16"><path fill="currentColor" fill-rule="evenodd" d="M4.705 10.038a.889.889 0 0 0 1.257 1.257l2.222-2.222a1.517 1.517 0 0 0 0-2.146L5.962 4.705a.889.889 0 1 0-1.257 1.257L6.743 8l-2.038 2.038Zm5.073-.26a.889.889 0 1 0 0 1.778h2.666a.889.889 0 1 0 0-1.778H9.778Zm8-5.392v7.228c0 .788 0 1.467-.073 2.01-.079.583-.255 1.142-.708 1.595-.453.453-1.012.63-1.595.708-.543.073-1.222.073-2.01.073H4.386c-.788 0-1.467 0-2.01-.073-.583-.078-1.142-.255-1.595-.708-.453-.453-.63-1.012-.708-1.594C0 13.08 0 12.402 0 11.614V4.386c0-.788 0-1.467.073-2.01C.151 1.792.328 1.233.781.78 1.234.328 1.793.151 2.375.073 2.92 0 3.598 0 4.385 0h9.007c.788 0 1.467 0 2.01.073.583.078 1.142.255 1.595.708.453.453.63 1.012.708 1.594.073.544.073 1.223.073 2.01Z" clip-rule="evenodd"></path></svg>
                                        <Label className='font-bold text-black ml-2'>Sortie Console</Label>
                                    </div>
                                    <Row>
                                        {
                                            isLoadingTest ?

                                                <Spinner
                                                    color="dark"
                                                    style={{
                                                        height: '3rem',
                                                        width: '3rem'
                                                    }}
                                                    type="grow"
                                                >
                                                    Loading...
                                                </Spinner>
                                                :
                                                resultatTest && <>
                                                    <h4 className={(resultatTest.success) ? "text-success" : "text-danger"}>{resultatTest.msg}</h4>
                                                    <Input
                                                        disabled
                                                        type="textarea"
                                                        rows="6"
                                                        className={`border-[.8px] px-2 border-gray-300 flex-grow py-[1.7px] text-sm h-6 form-control ${!resultatTest?.success ? "border-danger" : "border-success"}`}
                                                        placeholder="Mettre l'énoncé de la question ici*"
                                                        value={resultatTest.output}
                                                    />

                                                </>
                                        }

                                    </Row> */}
                                    {/* réponse candidat end*/}
                                    <Row className='mt-2 mb-2 justify-content-center'>
                                        {/*!FollowUp_IACode_Questions ? <Button
                                            style={{ backgroundColor: "#1188ef" }}
                                            onClick={HandleSubmitClick}
                                        >
                                            Soumettre votre solution et passer à la question suivante
                                        </Button>
                                            :
                                            <FollowUpIaQuestions
                                                questionId={query.questionId}
                                                followUpQuestion={followUpQuestion}
                                                setFollowUpQuestion={setFollowUpQuestion}
                                                HandleSubmitClick={HandleSubmitClick}
                                                onChange={HandleResponseChange}
                                            />
                                        */}
                                        <Button
                                            style={{ backgroundColor: "#1188ef" }}
                                            onClick={HandleSubmitClick}
                                        >
                                            Soumettre votre solution et passer à la question suivante
                                        </Button>
                                    </Row>


                                </Col>

                                <Col xs="1" >

                                </Col>


                            </Row>

                        </>
                    }



                    {timeOut && !stopRecordingFunctionCalled &&
                        <>
                            {setIndex(index + 1)}
                            {handleStopCapture()}

                            <Row className="justify-content-center" style={{ width: "100%" }}>
                                <Button
                                    className="text-white"
                                    style={{ backgroundColor: "#11a0ef" }}
                                    onClick={() => {
                                        //handleStopCapture();
                                        setModalOpen(!modalOpen);
                                        setStopRecordingFunctionCalled(true);

                                    }}
                                // className="	flex items-center justify-center py-3 text-white rounded-t-md"
                                // className="btn position-absolute d-flex top-0 left-0  text-green px-3 py-2"
                                // style={{ backgroundColor: "#00000075", height: "50px" }}
                                // style={{textAlign:"center"}}
                                // className="flex items-center justify-center"
                                >
                                    <p>Passer à la question suivante</p>
                                </Button>



                            </Row>

                        </>
                    }
                    {timeOut && stopRecordingFunctionCalled && !lastSaveFunctionCalled &&
                        <>
                            {/*
                setTimeout(() => 
                { 
                  lastSaveVideo();
                }, 5000)
              */}
                            <Row className="justify-content-center" style={{ width: "100%" }}>
                                <Button
                                    className="text-white"
                                    style={{ backgroundColor: "#11a0ef" }}
                                    onClick={() => {
                                        setModalOpen(!modalOpen);
                                        setStopRecordingFunctionCalled(true);
                                        //setIndex(index + 1);
                                    }}
                                // className="	flex items-center justify-center py-3 text-white rounded-t-md"
                                // className="btn position-absolute d-flex top-0 left-0  text-green px-3 py-2"
                                // style={{ backgroundColor: "#00000075", height: "50px" }}
                                // style={{textAlign:"center"}}
                                // className="flex items-center justify-center"
                                >
                                    <p>Passer à la question suivante</p>
                                </Button>



                            </Row>

                        </>
                    }




                </>




                <Row>
                    {(loading || lastSaveFunctionCalled) && (
                        <div className="absolute flex justify-center items-center top-0 left-0 z-50 bg-gray-800 w-full h-full opacity-80">
                            <ClipLoader color="#f00" />
                        </div>
                    )}
                </Row>

                {/* <Row>
                    {!loading && !capturing && !finishCapturing && (
                        <>
                            <Button
                                onClick={handleStartCapture}
                                className="text-white"
                                style={{ backgroundColor: "#11a0ef" }}
                            >

                                <p>Montrer la question</p>
                            </Button>
                        </>
                    )}
                </Row> */}
                {!timeOut &&
                    <Row>
                        <>

                            <Col xs="3" >
                            </Col>
                            <Col xs="6">
                                <Webcam style={{ opacity: opacity }} videoConstraints={{ deviceId: deviceId }} ref={webcamRef} />
                            </Col>
                            <Col xs="3" >
                            </Col>


                        </>
                    </Row>

                }



                <Modal
                    toggle={() => setModalRetakeOpen(!modalRetakeOpen)}
                    isOpen={modalRetakeOpen}

                >
                    <div className=" modal-header">
                        <h4 className=" modal-title" id="exampleModalLabel">
                            Êtes-vous sûr de vouloir reprendre la question ?
                        </h4>
                        <button
                            aria-label="Close"
                            className=" close"
                            type="button"
                            onClick={() => {
                                setModalRetakeOpen(!modalRetakeOpen);
                            }}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>

                    <ModalFooter>
                        <Button
                            color="secondary"
                            type="button"
                            onClick={() => {
                                setModalRetakeOpen(!modalRetakeOpen);
                            }}
                        >
                            Annuler
                        </Button>
                        <Button
                            color="primary"
                            type="button"
                            onClick={() => {
                                retakeCallback();
                            }}
                        >
                            Confirmer
                        </Button>
                    </ModalFooter>
                </Modal>

                <Modal
                    toggle={() => setModalOpen(!modalOpen)}
                    isOpen={modalOpen && !lastSaveFunctionCalled}
                    onClosed={() => {
                        // setIndex(index - 1);
                    }}
                >
                    <div className=" modal-header">
                        <h4 className=" modal-title" id="exampleModalLabel">
                            Soumettre et passer à la question suivante
                        </h4>
                        <button
                            aria-label="Close"
                            className=" close"
                            type="button"
                            onClick={() => {
                                setModalOpen(!modalOpen);

                            }}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>

                    <ModalFooter>
                        <Button
                            color="secondary"
                            type="button"
                            onClick={() => {
                                setModalOpen(!modalOpen);
                            }}
                        >
                            Annuler
                        </Button>
                        <Button
                            color="primary"
                            type="button"
                            onClick={() => {
                                lastSaveVideo();
                                setTakesIndexer(-1);
                            }}
                        >
                            Confirmer
                        </Button>
                    </ModalFooter>
                </Modal>

            </div>
        </>
    );
}

export default RecordCodingQuestion;
