import config from 'config'
import { fetchJobAppCandidatesWithAnonym } from 'network/ApiAxios'
import React, { useEffect, useState } from 'react'
import { Alert, Row } from 'reactstrap';

const JobAppAudiosSF = ({ id }) => {

    const [videos, setVideos] = useState([]);
    const [msg, setMsg] = useState(null);
    const [video, setVideo] = useState(null);
    const [index, setIndex] = useState(null);

    const getJobAppVideos = async () => {
        try {
            const { data } = await fetchJobAppCandidatesWithAnonym(id);
            if (data.msg) {
                setMsg(data.msg);
            }
            else {
                setVideos(data)
                if(data.length) {
                    const jobAppId = data[0]?.candidat
                    console.log('jobAppId',jobAppId)
                    localStorage.setItem('jobAppId', jobAppId)
                }

            }
            console.log(data);
        } catch (error) {
            console.log(error);
        }
    }

    const videoUrlHandler = (videoObj, idx) => {
        setVideo(videoObj);
        setIndex(idx + 1)
    };

    useEffect(() => {
        getJobAppVideos()
    }, [])
    return (
        <>
            <div className="flex  space-x-2 py-3 justify-between">
                <ul style={{ overflowY: 'auto', maxHeight: '500px' }} className="w-1/2 py-3 list-decimal list-inside bg-white  shadow-xl h-1/2 rounded-md">

                    <h3 className="flex px-3 py-3 bg-white text-black font-bold ">
                        Questions d'entretien
                        <span style={{ marginLeft: "auto" }}>
                        </span>
                    </h3>
                    {(!videos || msg) && <Row className='p-2 m-1'>
                        <h5>Le candidat n'a pas des audios</h5>
                    </Row>}
                    {videos &&
                        videos.map((video, index) => (
                            <>

                                {((!video?.question?.type) || ((video?.question?.type === "Video"))) && (
                                    <li
                                        className="border-b question border-gray-600 text-sm py-2 px-2 text-left last:border-b-0 text-black cursor-pointer transition-all duration-100 transform hover:bg-gray-400  hover:border-none"
                                        key={video._id}
                                        onClick={videoUrlHandler.bind(
                                            this,
                                            video,
                                            index
                                        )}
                                    >
                                        {video?.question?.question}
                                    </li>
                                )
                                }


                            </>


                        ))}
                </ul>
                <div className="w-1/2 py-3 list-decimal list-inside bg-white shadow-xl h-1/2 rounded-md">

                    <div className=" question text-sm py-2 px-2 text-left last:border-b-0 text-black">
                        {index}{index && '.'} {video?.question?.question}
                    </div>

                    <>
                        {video?.video && <audio
                            src={`${config.Video_URL}${video?.video}`}
                            type="audio/mp3"
                            controls
                            controlsList="nodownload"
                        />}

                    </>

                </div>
            </div>
        </>
    )
}

export default JobAppAudiosSF