import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({ component: Component, ...rest }) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const token = localStorage.getItem("token");
    return (
        <Route {...rest} render={(props) => (
            (token && user) ? <Component {...props} />

            : <Redirect to='/home/homepage' />
        )} />)
};

export default PrivateRoute;
