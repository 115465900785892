import React, { useEffect, useState } from "react";

// reactstrap components
import {
    CardBody,
    FormGroup,
    Form,
    Input,
    // Container,
    Row,
    Col,
} from "reactstrap";
import { Container, Box, Button, Card, Grid } from "@mui/material";
import CandidateLayoutV3 from "layouts/CandidateLayoutV3";
import { options_Pays } from "options";
import Select from "react-select";


// core components

import axios from "axios";
import config from "config";
import CandidatePreferences from "components/candidat/CandidatePreferences";


const instance = axios.create({
    baseURL: config.WS_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
    const token = localStorage.getItem("token");
    config.headers.Authorization = token ? token : "";
    config.headers.ContentType = "application/json";
    return config;
});

const CandidateParametersViewV1 = (props) => {
    let user = JSON.parse(localStorage.getItem("user"));

    const [name, setName] = useState(user.name);
    const [lastName, setLastName] = useState(user.lastName);
    const [email, setEmail] = useState(user.email);
    const [isTestUser, setIsTestUser] = useState(false);
    const [phone, setPhone] = useState(user.phone);
    const [description, setDescription] = useState(user.description);
    const [location, setLocation] = useState(user.location);


    useEffect(() => {
        if (JSON.parse(localStorage.getItem("user")).email === "test@test.com") {
            setIsTestUser(true);
        }
    }, []);

    const editUser = async () => {
        const formData = new FormData();
        formData.append("userID", user._id);
        formData.append("name", name);
        formData.append("lastName", lastName);
        formData.append("email", email);
        formData.append("phone", phone);
        formData.append("description", description);
        formData.append("location", location);
        const response = await instance.post("/users/edit", formData);
        const { data } = response;
        if (data.success) {
            user = {
                ...user,
                name,
                lastName,
                email,
                phone,
                description,
                location
            };
            localStorage.setItem("user", JSON.stringify(data.user[0]));
            window.location.reload();
        }
    };

    return (
        <>
            {/* <EditHeader /> */}
            <CandidateLayoutV3 />
            {/* Page content */}
            <Box
                sx={
                    {
                        ml: 32,
                        p: "30px",
                        mb: 10
                    }
                }
                display="flex"
                alignItems="center"
                justifyContent="center"
            >


                <h2>Paramètres</h2>

            </Box>
            <Box sx={
                {
                    ml: 32,
                    p: "30px"
                }
            }>
                <Container className="mt--7" >
                    <Row>
                        <div className="col">
                            <Card className="bg-secondary shadow">

                                <CardBody className="bg-white">
                                    <Form>
                                        <div className="pl-lg-4">

                                        </div>
                                        <h6 className="heading-small text-muted mb-4">Information</h6>
                                        <div className="pl-lg-4">
                                            <Row>
                                                <Col lg="3">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-username"
                                                        >
                                                            Nom
                                                        </label>
                                                        <Input
                                                            className="form-control-alternative"
                                                            value={name}
                                                            id="input-username"
                                                            placeholder="Username"
                                                            onChange={(e) => setName(e.target.value)}
                                                            type="text"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="3">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-username"
                                                        >
                                                            Prénom
                                                        </label>
                                                        <Input
                                                            className="form-control-alternative"
                                                            value={lastName}
                                                            id="input-username"
                                                            placeholder="Username"
                                                            onChange={(e) => setLastName(e.target.value)}
                                                            type="text"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="6">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-email"
                                                        >
                                                            Email
                                                        </label>
                                                        <Input
                                                            className="form-control-alternative"
                                                            id="input-email"
                                                            value={email}
                                                            onChange={(e) => setEmail(e.target.value)}
                                                            type="email"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </div>
                                        <hr className="my-4" />
                                        <div className="pl-lg-4">
                                            <Row>
                                                <Col lg="3">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-phone"
                                                        >
                                                            Numéro de téléphone
                                                        </label>
                                                        <Input
                                                            className="form-control-alternative"
                                                            value={phone}
                                                            id="input-phone"
                                                            // placeholder="Username"
                                                            onChange={(e) => setPhone(e.target.value)}
                                                            type="number"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </div>

                                        <div className="pl-lg-4">
                                            <Row>
                                                <Col lg="3">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-username"
                                                        >
                                                            Pays
                                                        </label>
                                                        <Select className="w-full "
                                                            options={options_Pays}
                                                            value={location}
                                                            placeholder={location}
                                                            onChange={(e) => {
                                                                setLocation(e.value);
                                                            }} />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </div>
                                        <hr className="my-4" />
                                        {/* Description */}

                                    </Form>
                                </CardBody>
                            </Card>
                        </div>
                    </Row>
                </Container>
            </Box>
            <Box
                sx={
                    {
                        ml: 32,
                        p: "30px"
                    }
                }
                display="flex"
                alignItems="center"
                justifyContent="center"
            >

                <Button
                    color="primary"
                    // href="#pablo"
                    variant="contained"
                    onClick={editUser}
                    //size="sm"
                    disabled={isTestUser}
                >
                    Sauvegarder
                </Button>

            </Box>
            <CandidatePreferences />
        </>
    );
};

export default CandidateParametersViewV1;
