import React, { useState } from "react";
import { NavLink as NavLinkRRD, Link, useHistory } from "react-router-dom";
// nodejs library to set properties for components
import config from "config";

import { logout } from "../../network/ApiAxios";

//import EtrangerCDIenFranceOffres from "views/candidat/etrangerCDIenFranceOffres";

// reactstrap components
import {
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Collapse,
  Row,
} from "reactstrap";

import NavDropdown from 'react-bootstrap/NavDropdown';
import useCustomTranslation from "components/other/useCustomTranslation";
import TranslationLang from "components/other/TranslationLang";





function SidebarEmployer() {
  var user;
  if (localStorage.getItem("user")) {
    user = JSON.parse(localStorage.getItem("user"));
  } else {
    user = { role: "none" };
  };
  const history = useHistory();
  const logOut = async () => {
    const token = localStorage.getItem("token");
    if (token) {
      const response = await logout(token);
      const { data } = response;
      if (data.success) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        // props.history.push("/auth/login");
        history.push("/home/homepage");
      }
    }
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  const { t, i18n } = useCustomTranslation();

  return (
    <>
      <Navbar
        className="navbar-vertical fixed-left navbar-light "
        expand="md"
        id="sidenav-main"
      >
        <Container fluid>
          {/* Toggler */}
          <button
            onClick={toggleNavbar}
            className="navbar-toggler"
            type="button"
          // onClick={this.toggleCollapse}
          >
            <span className="navbar-toggler-icon" />
          </button>
          {/* Brand */}

          <NavbarBrand
            className="pt-0"
            to="/employer/home"
            tag={Link}
          >
            <img
              src={require("assets/img/brand/logo_transparent.png").default}
              alt={"..."}
              className=""
              style={{ maxHeight: "fit-content" }}
            />

          </NavbarBrand>



          <Collapse isOpen={isOpen} navbar>
            <Nav
              className="" navbar
            >
              {/* console.log("cas 333333333333333333333333") */}

              <NavItem
              >
                <NavLink
                  tag={NavLinkRRD}
                  // onClick={this.closeCollapse}
                  // activeClassName="active"
                  to="/employer/home" >
                  <i className={"ni ni-briefcase-24"} />
                  {t('NavBarLink1')}
                </NavLink>
              </NavItem>

              <NavItem
              >
                <NavLink
                  tag={NavLinkRRD}
                  // onClick={this.closeCollapse}
                  // activeClassName="active"
                  to="/employer/Subscription" >
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" fill="none" viewBox="0 0 18 16" className="sib-dropdown-option__icon mr-3"><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 2.667H3c-.736 0-1.333.597-1.333 1.333v8c0 .736.597 1.333 1.333 1.333h12c.736 0 1.333-.597 1.333-1.333V4c0-.736-.596-1.333-1.333-1.333zm-13.333 4h14.666"></path></svg>
                  {t('Recruiter_SideBar_key1')}
                </NavLink>
              </NavItem>

              {user.accessLevel === "Administrator" &&
                <NavItem>
                  <NavLink
                    tag={NavLinkRRD}
                    // onClick={this.closeCollapse}
                    // activeClassName="active"
                    to="/employer/team" >
                    <i className={"ni ni-briefcase-24"} />
                    {t('Recruiter_SideBar_key2')}
                  </NavLink>
                </NavItem>
              }
              <NavItem>
                <NavLink
                  tag={NavLinkRRD}
                  // onClick={this.closeCollapse}
                  // activeClassName="active"
                  to="/employer/jobs" >
                  <i className={"ni ni-briefcase-24"} />
                  {t('Recruiter_SideBar_key3')}
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  tag={NavLinkRRD}
                  // onClick={this.closeCollapse}
                  // activeClassName="active"
                  to="/employer/candidatesView" >
                  <i className={"ni ni-briefcase-24"} />
                  {t('Candidates')}
                </NavLink>
              </NavItem>

              <NavDropdown title={t('Recruiter_SideBar_key4')} id="basic-nav-dropdown">
                <NavDropdown.Item as={Link} to="/employer/recruiters/FrUsersForRecrutersView">{t('Recruiter_SideBar_key5')}</NavDropdown.Item>
                {/* <NavDropdown.Item href="/employer/recruiters/OpenedAndQualifiedProfiles">Profils Ouverts</NavDropdown.Item>
                  <NavDropdown.Item href="/employer/recruiters/Qualify">Qualifier</NavDropdown.Item>
                  <NavDropdown.Item href="/employer/recruiters/AccessRequestsAccepted">Demandes d'accès acceptées</NavDropdown.Item> */}
              </NavDropdown>

              <NavDropdown title={t('Recruiter_SideBar_key6')} id="basic-nav-dropdown">
                <NavDropdown.Item as={Link} to="/employer/recruiters/OutFrUsersForRecrutersView">{t('Recruiter_SideBar_key5')}</NavDropdown.Item>
                {/* <NavDropdown.Item href="/employer/recruiters/OpenedOutFrProfiles">Profils Ouverts</NavDropdown.Item>                  */}
              </NavDropdown>

              <NavDropdown title={t('Recruiter_SideBar_key7')} id="basic-nav-dropdown">
                <NavDropdown.Item as={Link} to="#">{t('Recruiter_SideBar_key8')}</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="#">{t('Recruiter_SideBar_key9')}</NavDropdown.Item>
              </NavDropdown>

              {/* <NavItem> 
                <NavLink
                tag={NavLinkRRD}
                // onClick={this.closeCollapse}
                // activeClassName="active"
                to="stagiaires" >
                {t('Recruiter_SideBar_key10')}
                </NavLink>
                </NavItem> */}



              {/*
                  <NavItem> 
                  <NavLink
                    tag={NavLinkRRD}
                    // onClick={this.closeCollapse}
                    // activeClassName="active"
                    to="/employer/recruit/contractprofiles" >
                    <i className={"ni ni-briefcase-24"} />
                    Recruter un étranger en CDI
                  </NavLink>
                  </NavItem>

                  <NavItem> 
                  <NavLink
                    tag={NavLinkRRD}
                    // onClick={this.closeCollapse}
                    // activeClassName="active"
                    to="/employer/recruit/contractprofileswithlists" >
                    <i className={"ni ni-briefcase-24"} />
                    Etg CDI: Pipeline selon le profil
                  </NavLink>
                  </NavItem>

                  <NavItem> 
                  <NavLink
                    tag={NavLinkRRD}
                    // onClick={this.closeCollapse}
                    // activeClassName="active"
                    to="/employer/recruit/contractprofileswithlistsandaccessrules" >
                    <i className={"ni ni-briefcase-24"} />
                    Etg CDI: Pipeline selon le profil v2
                  </NavLink>
                  </NavItem>

                  <NavItem> 
                  <NavLink
                    tag={NavLinkRRD}
                    // onClick={this.closeCollapse}
                    // activeClassName="active"
                    to="/employer/parameters/OutFrProfilesAccessRulesEmployer" >
                    <i className={"ni ni-briefcase-24"} />
                    Etg CDI: Paramètres d'accès
                  </NavLink>
                  </NavItem>

                  <NavItem> 
                  <NavLink
                    tag={NavLinkRRD}
                    // onClick={this.closeCollapse}
                    // activeClassName="active"
                    to="/employer/talentsprofiles" >
                    <i className={"ni ni-briefcase-24"} />
                    Candidats
                  </NavLink>
                  </NavItem>
        */}

              {/* console.log ("333333333333333333333333: SidebarEmployer:") */}
              {/* console.log ("333333333333333333333333: SidebarEmployer: user.employerCampanyID: ", user.employerCampanyID) */}

              <>
                {/* <NavDropdown title={t('Train')} id="basic-nav-dropdown">

                          <NavDropdown title="Former vos collaborateurs" id="basic-nav-dropdown">
                            <NavDropdown.Item href="/employer/trainings/UserManagement">Ajouter des collaborateurs</NavDropdown.Item>  
                            <NavDropdown.Item href="/employer/trainings/RecruiterTrainingsTracking">Suivre</NavDropdown.Item>
                            <NavDropdown.Item href="/employer/trainings/CreateLearningPathView">Créer un parcours de formation</NavDropdown.Item>
                            <NavDropdown.Item href="/employer/trainings/YourLearningPathsView">Vos parcours de formation</NavDropdown.Item>
                        </NavDropdown>

                        </NavDropdown> */}

                {/* <NavDropdown title="Compétitions" id="basic-nav-dropdown">
                            <NavDropdown.Item href="/employer/Competitions/CompetitionForm">Créer une compétition</NavDropdown.Item>
                            
                            <NavDropdown.Item href="/employer/Competitions/EmployerCompetitions">Compétitions pour employeur</NavDropdown.Item>
                            <NavDropdown.Item href="/employer/Competitions/oneCompetition/61dd51901b81b1f6c375e038">Une compétition</NavDropdown.Item>
                            
                        </NavDropdown> */}





                {/*
                        <NavDropdown title="Tous" id="basic-nav-dropdown">
                          <NavDropdown.Item href="/employer/suivi/UsersView">Tous</NavDropdown.Item>  
                        </NavDropdown>
                      */}





              </>




              <NavItem>
                <NavLink
                  tag={NavLinkRRD}
                  // onClick={this.closeCollapse}
                  // activeClassName="active"
                  to="/employer/parameters" >
                  <i className={"ni ni-briefcase-24"} />
                  {t('OutFrSidebarKey2')}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  tag={NavLinkRRD}
                  onClick={logOut}
                  // onClick={this.closeCollapse}
                  // activeClassName="active"
                  to="/home/homepage"
                >
                  <i className={"ni ni-briefcase-24"} />
                  {t('Deconnect')}
                </NavLink>
              </NavItem>

            </Nav>
          </Collapse>

          {/* Divider */}
          <hr className="my-3" />
          <Row className='justify-content-center mb-2'>
            <TranslationLang />
          </Row>




        </Container>
      </Navbar>




    </>


  )

};


export default SidebarEmployer;
