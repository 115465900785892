import PageTitleStyle from 'components/styles/PageTitleStyle'
import PageSpace from 'components/styles/pageSpace'
import { fetchActiveTrainings } from 'network/ApiAxios'
import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, CardTitle, Col, Container, Row, Spinner } from 'reactstrap'
import { useHistory } from 'react-router-dom';

import OneTrainingForCandidateCard from './OneTrainingForCandidateCard'
import { CheckCandidateSubscription, getAllActiveSubscriptionProducts } from 'network/ApiAxios';
import useCustomTranslation from 'components/other/useCustomTranslation'

const TrainingLists = ({ type }) => {
    //console.log('type', type);
    const [activesTrainings, setActivesTrainings] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    const [hasActiveSubscription, setHasActiveSubscription] = useState(null);
    const [candidateActiveSubscription, setCandidateActiveSubscription] = useState([]);
    const [subscriptions, setSubscriptions] = useState([]);
    const user = JSON.parse(localStorage.getItem('user'));
    const { t } = useCustomTranslation();

    const history = useHistory();

    const GetActiveTrainings = async () => {
        //setIsLoading(true)
        try {
            const { data } = await fetchActiveTrainings(type)
            data && setActivesTrainings(data)
        } catch (error) {
            console.log(error)
        }
        /* finally {
            setIsLoading(false)
        } */
    }

    const CheckSubscription = async () => {
        try {
            const { data } = await CheckCandidateSubscription(user?.email);
            console.log('check', data)
            setHasActiveSubscription(data.hasActiveSubscription)
            data?.subscriptions?.length && setCandidateActiveSubscription(data.subscriptions);

        } catch (error) {
            console.log(error)
        }
    }

    const getActiveSubsciptionLists = async () => {
        try {
            const { data } = await getAllActiveSubscriptionProducts()
            setSubscriptions(data)
        } catch (error) {
            console.log(error);
        }
    }

    const findActiveSub = (products, sub) => {
        const match = products.some(product =>
            product.productId === sub[0]?.items?.data[0]?.plan?.product
        )
        console.log('match', match)
        return match;
    }

    const AllFcts = async()=>{
        setIsLoading(true)
        await GetActiveTrainings()
        await getActiveSubsciptionLists()
        await CheckSubscription()
        setIsLoading(false)
    }
    

    useEffect(() => {
        AllFcts()
    }, [type])

    return (
        <Container>
            <PageSpace />
            <PageTitleStyle
                title={t('TrainingList')}
            >

            </PageTitleStyle>

            <Row className="justify-content-center" style={{ width: "100%" }}>
                <Col sm="2" >
                </Col>
                <Col sm="10">
                    <Card className="bg-secondary shadow">
                        <CardBody className="bg-white d-flex justify-content-center flex-wrap" style={{ gap: '20px' }} /*style={{ display: 'grid', gridTemplateColumns: 'auto auto', justifyContent: 'space-around', rowGap: '20px' }}*/>
                            {isLoading ? <div className='w-full d-flex justify-content-center'>
                                <Spinner
                                    color="primary"
                                    type="grow"
                                >
                                    Loading...
                                </Spinner>
                            </div>
                                :
                                <>
                                    {/*
                                        !findActiveSub(subscriptions, candidateActiveSubscription) ?
                                        <Card
                                            className="my-2 text-center p-4 shadow mb-5 bg-white rounded"
                                            color="primary"
                                            outline
                                            style={{ width: "22rem" }}
                                        >
                                            <CardBody>
                                                <CardTitle tag="h3">
                                                    Veuillez s'abonner à l'offre Standard pour pouvoir accèder aux {(type === "Course")? "cours" : "parcours"}
                                                </CardTitle>
                                                <Button
                                                    size='sm'
                                                    color='primary'
                                                    onClick={() => history.push('/candidate/subscriptions')}
                                                >
                                                    Abonnements
                                                </Button>
                                            </CardBody>
                                        </Card>
                                        : */
                                        (activesTrainings && activesTrainings.length) ? activesTrainings.map(training =>
                                            <OneTrainingForCandidateCard
                                                item={training}
                                                key={training._id} 
                                                hasActiveSubscription={findActiveSub(subscriptions, candidateActiveSubscription)}
                                                />
                                        )
                                        :
                                        null
                                    }
                                </>

                            }
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default TrainingLists